import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

import { miniGazelle, dataLayer } from '../../../helpers';

import './ChangeCountry.scss';
import ExpandCollapseButtons from '../../shared/ExpandCollapseButtons/ExpandCollapseButtons';

/**
 * @class ChangeCountry
 *
 * Module that lists all available region/country/language combinations to allow the user to change their selected country or language.
 */

const ChangeCountry = (props = {}) => {
    const { data = {}, name } = props;
    const { target = '' } = data;

    const [loaded, setLoaded] = useState(false);
    const [regions, setRegions] = useState(data.regions);

    const title = miniGazelle.label('regions', true);

    const dataLayerEvent = ({ position, open }) => {
        dataLayer.triggerEvent('block_expanded', {
            id: 'change-country',
            name,
            position,
            open,
        });
    };

    const expandOrCollapse = expand => {
        const updatedRegions = regions.map(region => ({
            ...region,
            open: expand,
        }));

        setRegions(updatedRegions);
    };

    const updateAriaHidden = (id, open) => {
        const contentElement = document.getElementById(id);
        if (contentElement) {
            contentElement.setAttribute('aria-hidden', !open);
        }
    };

    useEffect(() => {
        const updatedRegions = regions.map(region => ({
            ...region,
            open: false,
        }));

        setRegions(updatedRegions);
        setLoaded(true);

        // Reason for the timeout is to await the DOM to finish rendering
        setTimeout(() => {
            updatedRegions.forEach((region, i) => {
                updateAriaHidden(`collapsible-content-${i}`, region.open);
            });
        }, 0);
    }, []);

    const onOpen = (region, i) => {
        const updatedRegions = regions.map((r, index) => (index === i ? { ...r, open: true } : r));
        setRegions(updatedRegions);

        dataLayerEvent({ position: i, open: true });

        setTimeout(() => {
            const element = document.getElementById(`collapsible-trigger-${i}`);
            if (element) {
                element.focus();
            }
        }, 0);
    };

    const onClose = (region, i) => {
        const updatedRegions = regions.map((r, index) => (index === i ? { ...r, open: false } : r));
        setRegions(updatedRegions);

        dataLayerEvent({ position: i, open: false });

        setTimeout(() => {
            const element = document.getElementById(`collapsible-trigger-${i}`);
            if (element) {
                element.focus();
            }
        }, 0);
    };

    const expandCollapseConfig = {
        logicalName: data.logicalName,
        expandText: miniGazelle.label('expandText', true),
        collapseText: miniGazelle.label('collapseText', true),
        handleButtonClick: expandOrCollapse,
    };

    return (
        <div className="container-fluid module change-country" id="change-country">
            <div className="row">
                <div className="container change-country-wrapper">
                    <div className="tw-px-4 md:tw-flex tw-hidden tw-justify-end tw-items-center md:tw--mx-4">
                        <ExpandCollapseButtons {...expandCollapseConfig} />
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <h1 className="tw-mb-10">{title}</h1>
                        </div>
                        <div className="col-md-9">
                            <div className="panel-group">
                                {loaded &&
                                    regions.map((region, i) => {
                                        const { name, countries = [], open } = region;
                                        const key = name + (open ? 'true' : 'false');
                                        return (
                                            <Collapsible
                                                key={key}
                                                data-key={key}
                                                trigger={name}
                                                open={open}
                                                className="panel panel-default"
                                                openedClassName="panel panel-default"
                                                onOpen={() => onOpen(region, i)}
                                                onClose={() => onClose(region, i)}
                                                contentHiddenWhenClosed={open}
                                                triggerElementProps={{
                                                    id: `collapsible-trigger-${i}`,
                                                    tabIndex: 0,
                                                    role: 'button',
                                                    'aria-expanded': open,
                                                    'aria-controls': `collapsible-content-${i}`,
                                                }}
                                                contentElementId={`collapsible-content-${i}`}
                                                contentElementProps={{
                                                    'aria-hidden': !open,
                                                }}
                                            >
                                                <div className="panel-collapse">
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            <div className="col-md-12 panel-content">
                                                                <div className="row country-language-list">
                                                                    {countries &&
                                                                        countries.map((country = {}) => {
                                                                            const { name, languages = [] } = country;

                                                                            return languages.map(language => {
                                                                                const countryCode = `${language}_${name}`;
                                                                                const link = `/${countryCode}${target}`;

                                                                                return (
                                                                                    <div
                                                                                        key={countryCode}
                                                                                        className="col-md-3"
                                                                                    >
                                                                                        <div className="country-wrapper">
                                                                                            <a
                                                                                                href={link}
                                                                                                className="block-link"
                                                                                                aria-hidden={!open}
                                                                                                tabIndex={open ? 0 : -1}
                                                                                            >
                                                                                                {miniGazelle.label(
                                                                                                    `${countryCode}_countryLabel`,
                                                                                                    true
                                                                                                )}
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            });
                                                                        })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapsible>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ChangeCountry.propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.shape({
        logicalName: PropTypes.string,
        target: PropTypes.string.isRequired,
        regions: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                countries: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        languages: PropTypes.arrayOf(PropTypes.string).isRequired,
                    })
                ).isRequired,
            })
        ).isRequired,
    }),
};

export default ChangeCountry;
